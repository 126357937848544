import api from '@/services/api';

class LprSiteService {
    view(request) {
        return api.get('/lpr/activation/'+request.vendorName+'/'+request.id);
    }

    create(request) {
        return api.post('/lpr/activation/'+request.vendorName+'', request);
    }

    updateModule(request) {
        return api.put('/lpr/activation/'+request.vendorName+'/'+request.id, request.body);
    }

    activate(request) {
        return api.put('/lpr/activation/'+request.vendorName+'/activate/'+request.id);
    }

    deactivate(request) {
        return api.put('/lpr/activation/'+request.vendorName+'/deactivate/'+request.id);
    }

    company() {
        return api.get('/lpr/activation/pending');
    }
    
    vendor() {
        return api.get('/vendors');
    }
}

export default new LprSiteService();