import lprDahuaSettingService from "@/services/lpr/dahua/setting.service";

export const lprDahuaSetting = {
    namespaced: true,
    actions: {
        updateDahuaSettings({ commit }, request) {
            return lprDahuaSettingService.updateDahuaSettings(request)
            .then(
                response => { 
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
    },
    mutations: {},
};