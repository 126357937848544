<template>
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" :class="getToggleClass" id="accordionSidebar">
        <!-- Sidebar - Brand -->
        <router-link :to="{ path: '/home' }" :class="'sidebar-brand d-flex align-items-center'">
            <span class="sidebar-brand-icon">ECOM</span>
            <div class="sidebar-brand-text mx-3">ADMIN PANEL <sup></sup></div>
        </router-link>

         <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- Nav Item -->
        <li class="nav-item">
            <a class="nav-link" href="#" data-toggle="collapse" data-target="#mod-vms" aria-expanded="true" aria-controls="mod-vms" @click="onClickNavItem('mod-vms')">
                <font-awesome-icon :icon="['fas', 'user-shield']" />
                <span>VMS</span>
            </a>
            <div id="mod-vms" class="collapse" aria-labelledby="headingOne" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <a class="collapse-item" href="#" @click="onClickSiteActivation('mod-vms')">Site Activation</a>
                </div>
            </div>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#" data-toggle="collapse" data-target="#mod-key-collection" aria-expanded="true" aria-controls="mod-key-collection" @click="onClickNavItem('mod-key-collection')">
                <font-awesome-icon :icon="['fas', 'fa-key']" />
                <span>Key Collection</span>
            </a>
            <div id="mod-key-collection" class="collapse" aria-labelledby="headingOne" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <a class="collapse-item" href="#" @click="onClickSiteActivation('mod-key-collection')">Site Activation</a>
                </div>
            </div>
        </li>
        
        <li class="nav-item">
            <a class="nav-link" href="#" data-toggle="collapse" data-target="#mod-tapway-lpr" aria-expanded="true" aria-controls="mod-tapway-lpr" @click="onClickNavItem('mod-tapway-lpr')">
                <font-awesome-icon :icon="['fas', 'fa-car']" />
                <span>Tapway LPR</span>
            </a>
            <div id="mod-tapway-lpr" class="collapse" aria-labelledby="headingOne" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <a class="collapse-item" href="#" @click="onClickSiteActivation('mod-tapway-lpr')">Site Activation</a>
                </div>
            </div>
        </li>
        
        <li class="nav-item">
            <a class="nav-link" href="#" data-toggle="collapse" data-target="#mod-dahua-lpr" aria-expanded="true" aria-controls="mod-dahua-lpr" @click="onClickNavItem('mod-dahua-lpr')">
                <font-awesome-icon :icon="['fas', 'fa-car']" />
                <span>Dahua LPR</span>
            </a>
            <div id="mod-dahua-lpr" class="collapse" aria-labelledby="headingOne" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <a class="collapse-item" href="#" @click="onClickSiteActivation('mod-dahua-lpr')">Site Activation</a>
                </div>
            </div>
        </li>

        <!-- Nav Item -->
        <!-- <li class="nav-item">
            <a class="nav-link" href="#" data-toggle="collapse" data-target="#mod-eb" aria-expanded="true" aria-controls="mod-eb" @click="onClickNavItem('mod-eb')">
                <font-awesome-icon :icon="['fas', 'map-signs']" />
                <span>eBooking</span>
            </a>
            <div id="mod-eb" class="collapse" aria-labelledby="headingOne" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <a class="collapse-item" href="#" @click="onClickSiteActivation('mod-eb')">Site Activation</a>
                </div>
            </div>
        </li> -->

        <!-- Divider -->
        <hr class="sidebar-divider">

        <!-- Sidebar Toggler (Sidebar) -->
        <div class="text-center d-inline">
            <button type="button" class="rounded-circle border-0" id="sidebarToggle" @click="onClickSidebar()">
                <font-awesome-icon :icon="['fas', getIcon]" />
            </button>
        </div>
    </ul>
</template>

<script>
export default {
    name: 'LeftSidebar',
    methods: {
        onClickSidebar() {
            this.$store.dispatch('layout/update', { key: "leftSidebar", className: "toggled", icon: "angle-left" });
        },
        onClickSiteActivation(divId) {
            this.onClickNavItem(divId)
            if(divId == 'mod-vms')
            {
                this.$router.push({ name: 'Vms-SiteActivation' });
            }else if(divId == 'mod-key-collection')
            {
                this.$router.push({ name: 'KeyCollection-SiteActivation' });
            } else if(divId == 'mod-tapway-lpr') {
                this.$router.push({ name: 'Tapway-Lpr-SiteActivation' });
            } else if(divId == 'mod-dahua-lpr') {
                this.$router.push({ name: 'Dahua-Lpr-SiteActivation' });
            }
        },
        onClickNavItem(divId) {
            // a TAG
            const aElem = document.querySelector("a.nav-link[data-target='#"+divId+"']");
            if(aElem) {
                aElem.classList.toggle("collapsed");
            }
            
            // div TAG
            const divElem = document.getElementById(divId);
            if(divElem) {
                divElem.classList.toggle("show");
            }
        }
    },
    computed: {
        getToggleClass() {
            return this.$store.state.layout.leftSidebarToggleClass;
        },
        getIcon() {
            return this.$store.state.layout.leftSidebarIcon;
        }
    },
}
</script>