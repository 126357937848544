<template>
    <validation-error-message :errors="errors"></validation-error-message>
    <div class="row">
        <div class="col-12">
            <div class="d-sm-flex align-items-center justify-content-between mb-3">
                <div class="h4"><span>SITE ACTIVATION</span></div>
                <button type="button" class="btn btn-link btn-fontawesome"><font-awesome-icon :icon="['far', 'circle-xmark']" class="h3" @click="backToList" /></button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card shadow">
               <div class="card-body">
                    <form class="sites" @submit="submitCreateForm">
                        <div class="row">
                            <div class="col-sm-2">
                                <label class=" col-form-label">Site Name <span class="text-small text-danger">*</span></label>
                            </div>
                            <div class="col-sm-4">
                                <span class="spinner-border spinner-border-sm text-primary" v-show="isLoadingCompanyList"></span>
                                <select name="companyId" v-model="companyId.value.value" v-show="! isLoadingCompanyList">
                                    <option v-if="! isErrorCompanyList" v-for="option in companyList" :key="option.companyId" :value="option.companyId">{{ option.siteName }}</option>
                                    <option value="0" v-else>-- Error Loading --</option>
                                </select>
                                <span class="error-message validation">{{ errors.companyId }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-2">
                                <label>Start Date <span class="text-small text-danger">*</span> </label>
                            </div>
                            <div class="col-sm-4">
                                <Datepicker
                                    name="startDate"
                                    v-model="formInfo.startDate" 
                                    :enableTimePicker="false" 
                                    autoApply 
                                    noToday 
                                    :format="'dd/MM/yyyy'"
                                    :minDate="minDate"
                                    @update:modelValue="handleStartDate"
                                />
                                <span class="error-message validation">{{ errors.startDate }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-2">
                                <label>End Date <span class="text-small text-danger">*</span> </label>
                            </div>
                            <div class="col-sm-4">
                                <Datepicker 
                                    name="endDate"
                                    v-model="formInfo.endDate" 
                                    :enableTimePicker="false" 
                                    autoApply 
                                    noToday
                                    :minDate="minDate"
                                    :format="'dd/MM/yyyy'" 
                                />
                                <span class="error-message validation">{{ errors.endDate }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h6 class="pb-3 pt-3"><strong>API AUTHENTICATION SERVICE</strong></h6>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <div class="row mb-3">
                                    <div class="col-md-4">
                                        <label>Username: <span class="text-danger text-bold">*</span></label>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="position-relative">
                                            <Field :id="`username`" :name="`username`" class="form-control" type="text" />
                                            <ErrorMessage class="error-message" :name="`username`" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4">
                                        <label>Password: <span class="text-danger text-bold">*</span></label>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="position-relative">
                                            <Field :id="`password`" :name="`password`" class="form-control" type="password" />
                                            <ErrorMessage class="error-message" :name="`password`" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3" v-if="vendorName == 'tapway'">
                                    <div class="col-md-4">
                                        <label>Dashboard Url: <span class="text-danger text-bold">*</span></label>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="position-relative">
                                            <Field :id="`dashboardUrl`" :name="`dashboardUrl`" class="form-control" type="text" />
                                            <ErrorMessage class="error-message" :name="`dashboardUrl`" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4">
                                        <label>Server IP: <span class="text-danger text-bold">*</span></label>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="position-relative">
                                            <Field :id="`serverIp`" :name="`serverIp`" class="form-control" type="text" />
                                            <ErrorMessage class="error-message" :name="`serverIp`" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pt-3 text-center">
                            <button class="btn btn-primary" type="submit" :disabled="submittingForm">
                                <span class="spinner-border spinner-border-sm" v-show="submittingForm"></span>
                                <span v-show="! submittingForm">SUBMIT</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3"></div>
</template>

<script>
import { ref, reactive, toRefs } from "vue";
import { useField, useForm, Field, ErrorMessage } from "vee-validate";
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import * as yup from "yup";
import moment from 'moment';
import formSiteActivation from "@/composables/lpr/forms/form-site-activation";
import ValidationErrorMessage from "@/components/ValidationErrorMessage";
import displayToastNotification from "@/composables/toast-notification";

export default {
    name: "AddSiteActivation",
    components: { ValidationErrorMessage, Field, ErrorMessage },
    setup() {
        
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const toastNotification = displayToastNotification();
        const siteActivation = formSiteActivation();
        
        const submittingForm = ref(false);
        const companyId = useField('companyId');

        const formInfo = reactive({
            companyList: [],
            isErrorCompanyList: false,
            isLoadingCompanyList: true,
            minDate: moment().toString(),
            startDate: moment().toString(),
            endDate: moment().add(10, 'y').toString()
        })

        const vendorName = route.meta.vendorName;
        const componentName = (vendorName.charAt(0).toUpperCase() + vendorName.slice(1))+'-Lpr-SiteActivation'

        const fetchCompanyList = async () => {
            try {
                const response = await store.dispatch("lprSite/company");
                if(response.status === 200) {
                    formInfo.companyList = response.data.data;
                    companyId.value.value = formInfo.companyList.length > 0 ? formInfo.companyList[0].companyId : 0;
                }
            } catch (error) {
                formInfo.isErrorCompanyList = true;
            } finally {
                formInfo.isLoadingCompanyList = false;
            }
        }

        fetchCompanyList();

        let schema = yup.object({
            companyId: yup.number().min(1, "May I know which site?"),
            startDate: yup.date().nullable().when("isActiveStatus", (isActiveStatus, schema) => {
                if (!isActiveStatus) {
                    return schema; // If active status is true, make startDate optional
                } else {
                    return schema.required('Start date is required.'); // If active status is false, make startDate required
                }
            }),
            endDate: yup.date().nullable().when("isActiveStatus", (isActiveStatus, schema) => {
                if (!isActiveStatus) {
                    return schema; // If active status is true, make endDate optional
                } else {
                    return schema.required('End date is required.'); // If active status is false, make endDate required
                }
            }).when("startDate", (sd, schema) => {
                if (sd !== null && moment(sd).isValid()) {
                    return schema.min(moment(sd), "End date should be after the start date."); // Validate end date only if it's not null and valid
                } else {
                    return schema; // Don't apply min validation if start date is null or invalid
                }
            }),
            username: yup.string().required('This field is required.'),
            password: yup.string().required('This field is required.'),
            serverIp: yup.string().required('This field is required.'),
        });

        if (vendorName == 'tapway') {
            schema = schema.shape({
                dashboardUrl: yup.string().required('This field is required.'),
            });
        }

        const { handleSubmit, setErrors, errors } = useForm({ validationSchema: schema });

        const submitCreateForm = handleSubmit(values => {
            submittingForm.value = true;

            const sdt = formInfo.startDate;
            const edt = formInfo.endDate;
           
            const postBody = {
                companyId: companyId.value.value,
                startDate: moment(sdt).format("YYYY-MM-DD"),
                endDate: moment(edt).format("YYYY-MM-DD"),
                isActive: 1,
                username: values.username,
                password: values.password,
                dashboardUrl: values.dashboardUrl,
                serverIp: values.serverIp,
                vendorName: vendorName
            }
           
            store.dispatch("lprSite/create", postBody).then(
                (response) => {
                    toastNotification.insertSuccessNotification(); 
                    router.push({ name: componentName });
                },
                (error) => {
                    submittingForm.value = false;
                    if(error.response && error.response.data) {
                        if(error.response.status === 422) {
                            setErrors(error.response.data.error.validation)
                        } else {
                            toastNotification.errorNotification(error.response.data.error.message);
                        }
                    }
                }
            )
        });

        const handleStartDate = (dt) => {
            const thisDate = siteActivation.onUpdateStartDate(dt);
            if(thisDate) {
                formInfo.endDate = moment(thisDate);
            }
        }
        
        const backToList = () => {
            router.push({ name: componentName })
        }

        return {
            companyId,
            errors,
            handleStartDate,
            submitCreateForm,
            submittingForm,
            backToList,
            ...toRefs(formInfo),
            formInfo,
            Field,
            ErrorMessage,
            vendorName
        };
    }
}
</script>