<template>
    <nav aria-label="breadcrumb" id="breadcrumb">
        <ol class="breadcrumb">
            <li v-for="(item, index) in breadcrumb" :class="item.class" :key="index">
                <a href="#" v-if="item.href == '' || ((breadcrumb.length - 1) - index == 0)">{{ item.name }}</a>
                <router-link :to="{ name: item.href }" v-else>{{ item.name }}</router-link> 
            </li>
        </ol>
    </nav>
</template>

<script>
import { useRoute } from 'vue-router';
import { computed } from "vue";

export default {
    name: 'Breadcrumb',
    setup() {

        const breadcrumb =  computed(() => {
          
            const currentRouteName = useRoute().name;
            let arr = [{ name: "HOME", href: "Home", class: "breadcrumb-item", key: "pa-Home" }];
            
            if(currentRouteName.includes("Profile")) {
                arr.push({ name: "MY ACCOUNT", href: "Profile", class: "breadcrumb-item", key: "pa-Profile"});
            }
            
            if(currentRouteName.includes("Vms")) {

                // Check if Parent Module existed
                arr.map((item) => {
                    if(! item.key.includes("pa-vms")) {
                        arr.push({ name: "VMS", href: "", class: "breadcrumb-item", key: "pa-vms"});
                    }

                    if(! item.key.includes("pa-vms-siteactivation")) {
                        arr.push({ name: "SITE ACTIVATION", href: "Vms-SiteActivation", class: "breadcrumb-item", key: "pa-vms-siteactivation"});
                    }
                });
            }
            
            if(currentRouteName.includes("KeyCollection")) {
                arr.map((item) => {
                    if(! item.key.includes("pa-key-collection")) {
                        arr.push({ name: "KEY COLLECTION", href: "", class: "breadcrumb-item", key: "pa-key-collection"});
                    }
        
                    if(! item.key.includes("pa-key-collection-siteactivation")) {
                        arr.push({ name: "SITE ACTIVATION", href: "KeyCollection-SiteActivation", class: "breadcrumb-item", key: "pa-key-collection-siteactivation"});
                    }
                });
            }
            
            if(currentRouteName.includes("Lpr")) {
                arr.map((item) => {
                    // if route.meta.vendorName
                    if (useRoute().meta.vendorName == 'tapway') {
                        if(! item.key.includes("pa-tapway-lpr-siteactivation")) {
                            arr.push({ name: "SITE ACTIVATION", href: "Tapway-Lpr-SiteActivation", class: "breadcrumb-item", key: "pa-tapway-lpr-siteactivation"});
                        }
                    } else if (useRoute().meta.vendorName == 'dahua') {
                        if(! item.key.includes("pa-dahua-lpr-siteactivation")) {
                            arr.push({ name: "SITE ACTIVATION", href: "Dahua-Lpr-SiteActivation", class: "breadcrumb-item", key: "pa-dahua-lpr-siteactivation"});
                        }
                    }
                });
            }
          
            if(currentRouteName.includes("Add")) {
                arr.push({ name: "ADD NEW", href: arr[(arr.length - 1)].href+"-Add", class: "breadcrumb-item "});
            }

            if(currentRouteName.includes("View")) {
                arr.push({ name: "VIEW", href: arr[(arr.length - 1)].href+"-View", class: "breadcrumb-item "});
            }
          
            return arr;
        })

        return { breadcrumb }
    }
}
</script>