import { createStore } from "vuex";
import { layout } from "./app.layout";
import { paginator } from "./app.paginator";
import { auth } from "./auth.module";
import { profile } from "./profile.module";
import { vmsSetting } from "./vms/setting.module";
import { vmsSite } from "./vms/site.module";
import { keyCollectionSetting } from "./keycollection/setting.module";
import { keyCollectionSite } from "./keycollection/site.module";
import { lprSite } from "./lpr/site.module";
import { lprTapwaySetting } from "./lpr/tapway/setting.module";
import { lprDahuaSetting } from "./lpr/dahua/setting.module";

const store = createStore({
    modules: {
        auth,
        layout,
        paginator,
        profile,
        vmsSetting,
        vmsSite,
        keyCollectionSetting,
        keyCollectionSite,
        lprSite,
        lprTapwaySetting,
        lprDahuaSetting,
    }, 
});

export default store;