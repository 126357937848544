<template>
    <div v-show="hasErrors" class="alert alert-danger" role="alert">
        <ul v-if="isArray">
            <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
        </ul>
        <ul v-else-if="isObject">
            <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
        </ul>
        <span v-else>Whoops! There were some problems with your input</span>
    </div>
</template>

<script>
export default {
    name: 'ValidationErrorMessage',
    props: {
        errors: {
            type: [Array, Object],
            required: true
        },
    },
    computed: {
        isArray() {
            return Array.isArray(this.errors);
        },
        isObject() {
            return typeof this.errors === 'object' && !Array.isArray(this.errors) && this.errors !== null;
        },
        hasErrors() {
            return this.isArray ? this.errors.length > 0 : Object.keys(this.errors).length > 0;
        }
    }
}
</script>
<style scoped>
ul {
    margin-bottom: 0;
}
</style>